<script setup>
defineProps({
    name: {
        type: String,
        required: true
    },
    limit: {
        type: Number,
        required: true,
    },
    value: {
        type: Number,
        required: true,
    },
    before_value: {
        type: Number,
        required: false,
    },
    due_date: {
        type: String,
        default: "",
    },
    status: {
        type: String,
        default: "text-success"
    },
    id: {
        type: String,
        default: "",
    },
    icon: {
        type: String,
        default: "",
    },
    sum: {
        type: Number,
        required: false
    },
    fixes: {
        type: Number,
        required: false
    }
    
});

function formatMoney(v) {
      let total = parseFloat(v);

      return total.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    }

function formatDate(d){
    
    let date = new Date(d);

    let options = {
                    month: 'numeric',
                    day: 'numeric',
                    timeZone: 'UTC'
                };
    
    return date.toLocaleDateString('pt-BR', options);

}
</script>

<template>
    <div class="row align-items-center row-cards">

        <div class="col-5 align-content-between d-flex align-items-center">
            <img :src="icon" alt="Banco Logo" width="48" class="rounded-circle ms-2 me-3">
            <div>
                <h6 class="mt-n1 mb-n1">{{ name }}</h6>
                
                <span class="text-sm">{{ formatDate(due_date) }}</span>
            </div>
        </div>
        <div class="col-5 text-end">
            <span class="text-dark font-weight-bold">{{ formatMoney((parseFloat(value) + parseFloat(before_value))) }}</span>
        </div>
        <div class="col-2 text-end">

            <a :href="'/fatura/'+id" class="me-3">
                <i class="fal fa-file-invoice text-black-50 row-accounts-list-icons-actions" v-tooltip="{
                                    content:'Ver fatura',
                                    distance: 15
                                }"></i>
            </a>

        </div>

    </div>
</template>

<style>
    .row-cards{
        min-height: 70px;
    }

    .card-title{
            font-size: 14px;
        }

    @media (max-width: 575.98px) {
        .card-title{
            font-size: 13px;
        }
    }
</style>