<script setup>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import Accounts from "./components/Accounts.vue";
import Cards from "./components/Cards.vue";
import Transactions from "./components/Transactions.vue";
import { onBeforeMount, onMounted } from "vue";
import router from "../router";
import cookie from '../assets/js/cookies.js';
import validSession from "../assets/js/valid-session.js";
import { useStore } from "vuex";
import axios from "axios";
import SkeletonLoad from "./components/SkeletonLoad.vue";
import { toast } from 'vue3-toastify';
import DonutChart from "./components/DonutChart.vue";

const store = useStore();

onBeforeMount(() => {

  validSession.isLogged();

  store.state.loading_values = true;
  store.state.values = {
    balance: 0,
    in: 0,
    out: 0,
    cc: 0,
  }

  store.state.loading_accounts = true;
  store.state.accounts = [];

  store.state.loading_cards = true;
  store.state.cards = [];

  store.state.loading_transactions = true;
  store.state.transactions_in = [];
  store.state.transactions_out = [];

  store.state.loading_categoriesChart = true;
  store.state.categoriesChartData = [];
  
  store.state.categoriesChart = {
    chartData: {
          labels: ['VueJs', 'EmberJs', 'ReactJs', 'AngularJs'],
          datasets: [
            {
              backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16'],
              data: [40, 20, 80, 10]
            }
          ]
        },
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false
        }
  }

  getValues();
  getAccounts();
  getCards();
  getTransaction();
  getCategories();
    
})

onMounted(() => {

  let session = cookie.get('user_agive_session');

  if(session == null){
    router.push({name: 'Login'});
  }

});

const getValues = async () => {

  store.state.loading_values = true;

  axios.get('dashboard/values')
    .then(res => {
        
        if(res.status == 200){

            store.state.values = {
              balance: res.data.balance ? res.data.balance : 0,
              balance_future: res.data.balance_future ? res.data.balance_future : 0,
              in: res.data.in ? res.data.in : 0,
              in_payed: res.data.in_payed ? res.data.in_payed : 0,
              out: res.data.out ? res.data.out : 0,
              out_payed: res.data.out_payed ? res.data.out_payed : 0,
              cc: res.data.cc ? res.data.cc : 0,
              cc_payed: res.data.cc_payed ? res.data.cc_payed : 0,
            }

            store.state.loading_values = false;

        }
        
    })
    .catch(err => {
        console.error(err); 
    })

}

const getAccounts = async () => {

  axios.get('/dashboard/accounts')
  .then(res => {
      
      if(res.status == 200){

          store.state.accounts = res.data;
          store.state.loading_accounts = false;

      }
      
  })
  .catch(err => {
      console.error(err); 
  })

}

const getCards = async () => {

  axios.get('/dashboard/cards')
  .then(res => {
      
      if(res.status == 200){

          store.state.cards = res.data;
          store.state.loading_cards = false;

      }
      
  })
  .catch(err => {
      console.error(err); 
  })

}

const getTransaction = async () => {

  store.state.loading_transactions = true;

  axios.get('dashboard/transactions')
    .then(res => {
        
        if(res.status == 200){

            store.state.transactions_in = res.data.in;
            store.state.transactions_out = res.data.out;

            store.state.loading_transactions = false;

        }
        
    })
    .catch(err => {
        console.error(err); 
    })

}

const payTransation = (transaction) => {

  let url = '/transactions/pay/'+transaction.id;

  let data = new FormData();

  data.append('status', transaction.status == 'Paid' ? 'Unpaid' : 'Paid');

  axios.post(url, data)
  .then(res => {
      
      if(!res.data.error){

          toast('Lançamento atualizado com sucesso!', {
              autoClose: 1000,
              theme: "auto",
              type: "success",
              transition: "slide"
          });

          getTransaction();
          getValues();
          getAccounts();

      }

  })
  .catch(err => {
      console.error(err); 
  })


}

const getCategories = async () => {

  axios.get('dashboard/categories')
  .then(res => {
      
      if(res.status == 200){

        let chart_lables = [];
        let chart_values = [];
        let chart_colors = [];
        let total = 0;
        
        res.data.forEach((value) => {

          chart_lables.push(value.name);
          chart_colors.push(value.color);
          chart_values.push(value.amount);

          total = total + parseFloat(value.amount);

        });

        store.state.categoriesChart = {
          chartData: {
                labels: chart_lables,
                datasets: [
                  {
                    data: chart_values
                  }
                ]
              },
              chartOptions: {
                responsive: true,
                backgroundColor: chart_colors,
                maintainAspectRatio: true,
                plugins: {
                    title: {
                        display: true,
                        text: 'Despesas por categoria'
                    },
                    legend: {
                        display: true,
                        position: 'bottom',
                        maxWidth: 150,
                        labels: {
                            color: '#000',
                            usePointStyle: true
                        }
                    },
                    tooltip: {
                      callbacks: {
                        label: function(context) {
                          
                            let label = context.dataset.label || '';

                            if (label) {
                                label += ': ';
                            }
                            if (context.parsed.y !== null) {
                                label += new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(context.parsed);
                            }
                            return label;
                        }
                      }
                    },
                    datalabels: {
                      display: true,
                      align: 'center',
                      backgroundColor: '#0000001A',
                      borderRadius: 3,
                      color: 'white',
                      position: 'outside',
                      font: {
                        size: 10,
                      },
                      formatter: (value, ctx) => {
                        const total = ctx.chart.getDatasetMeta(0).total;
                        let percentage = (value * 100 / total).toFixed(2) + "%";
                        return percentage;
                      }
                    },
                   
                    doughnutlabel: {
                      labels: [
                        {
                          text: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(parseFloat(total)),
                          font: {
                            size: 20,
                            weight: 'bold',
                          },
                        },
                        {
                          text: 'total',
                        },
                      ],
                    },                  
                }
              }
        }

        store.state.categoriesChartData = res.data;

        store.state.loading_categoriesChart = false;

          //store.state.cards = res.data;
          //store.state.loading_cards = false;

      }
      
  })
  .catch(err => {
      console.error(err); 
  })

}

</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">

        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              :title="{
                text: 'Contas',
                color: ''
              }"
              :value="{
                text: store.state.values.balance,
                color: ''
              }"
              :future_value="{
                text: store.state.values.balance_future,
                color: ''
              }"
              description=""
              :icon="{
                component: 'fal fa-wallet',
                background: 'bg-gradient-primary',
                shape: 'rounded-circle',
              }"
              :loading="store.state.loading_values"
              :rowReverse="true"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              :title="{
                text: 'Receitas',
                color: ''
              }"
              :value="{
                text: store.state.values.in_payed,
                color: ''
              }"
              :future_value="{
                text: store.state.values.in,
                color: ''
              }"
              description=""
              :icon="{
                component: 'fal fa-plus',
                background: 'bg-gradient-success',
                shape: 'rounded-circle',
              }"
              :loading="store.state.loading_values"
              :rowReverse="true"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              :title="{
                text: 'Despesas',
                color: ''
              }"
              :value="{
                text: store.state.values.out_payed,
                color: ''
              }"
              :future_value="{
                text: store.state.values.out,
                color: ''
              }"
              description=""
              :icon="{
                component: 'fal fa-minus',
                background: 'bg-gradient-danger',
                shape: 'rounded-circle',
              }"
              :loading="store.state.loading_values"
              :rowReverse="true"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">

            <mini-statistics-card
              :title="{
                text: 'Cartões',
                color: ''
              }"
              :value="{
                text: store.state.values.cc_payed,
                color: ''
              }"
              :future_value="{
                text: store.state.values.cc,
                color: ''
              }"
              description=""
              :icon="{
                component: 'fal fa-credit-card',
                background: 'bg-gradient-info',
                shape: 'rounded-circle',
              }"
              :loading="store.state.loading_values"
              :rowReverse="true"
            />
            
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-8">

            <div class="row">

              <!--Linha minhas contas-->
              <div class="col-lg-6">

                <div class="card">
                  <div class="p-3 pb-0 card-header">
                    <div class="d-flex justify-content-between">
                      <h6 class="mb-2">Minhas Contas</h6>
                    </div>
                  </div>
                  <div v-if="store.state.loading_accounts" class="col-12">
                    <div class="col-12">

                      <div class="row align-items-center row-accounts">

                          <div class="col-2 col-lg-1 ms-3">
                            <SkeletonLoad
                                :width="'45px'"
                                :height="'45px'"
                                :class="'rounded-circle'"
                            />
                          </div>
                          <div class="col-3 col-lg-4">
                            <SkeletonLoad
                                :width="'90px'"
                                :height="'25px'"
                                :class="'ms-4'"
                            />
                          </div>
                          <div class="col-5 col-lg-6 text-end">
                            <SkeletonLoad
                                :width="'90px'"
                                :height="'25px'"
                                :class="'float-end'"
                            />
                          </div>

                      </div>

                    </div>
                  </div>
                  <div v-else class="col-12">
                    <Accounts
                      v-for="(account, index) in store.state.accounts" :key="index"
                      :bank="account.name"
                      :balance="account.balance"
                      :id="account.id"
                      :icon="account.icon"
                    />
                  </div>
                </div>

              </div>

              <!--Linha Cartões-->
              <div class="col-lg-6 mt-4 mt-lg-0 mb-4 mb-lg-0">

                <div class="card">
                  <div class="p-3 pb-0 card-header">
                    <div class="d-flex justify-content-between">
                      <h6 class="mb-2">Meus Cartões</h6>
                    </div>
                  </div>
                  <div v-if="store.state.loading_cards" class="col-12">
                    <div class="row align-items-center row-cards">

                      <div class="col-5 align-content-between d-flex align-items-center">
                          
                          <div>
                            <SkeletonLoad
                                :width="'90px'"
                                :height="'20px'"
                                :class="'ms-4 mt-n1 mb-n1'"
                            />
                                                           
                          </div>
                      </div>
                      <div class="col-5 text-end">
                        <SkeletonLoad
                            :width="'90px'"
                            :height="'20px'"
                            :class="'ms-3'"
                        />
                      </div>
                      <div class="col-2 text-end">

                      </div>

                      

                    </div>
                  </div>
                  <div v-else class="col-12">

                    <Cards
                     v-for="(card, index) in store.state.cards" :key="index"
                     :name="card.name"
                     :limit="card.limit"
                     :value="card.current_invoice.current_amount"
                     :before_value="card.current_invoice.before_amount"
                     :due_date="card.current_invoice.due_date"
                     :icon="card.icon"
                     :id="card.current_invoice.id"
                     :status="card.current_invoice.status"
                     :sum="card.sum[0].current_amount"
                     :fixes="card.future_fixed[0].amount ? card.future_fixed[0].amount : 0"
                    />
                    
                  </div>
                  <div class="col-12 text-center mt-4" v-if="store.state.cards.length == 0 && !store.state.loading_cards">
                        <p>Nenhum cartão cadastrado.</p>
                      </div>
                </div>

              </div>

              <div class="col-lg-12 mt-lg-4 mb-4">

                <div class="card">
                  <div class="p-3 pb-0 card-header">
                    <div class="d-flex justify-content-between">
                      <h6 class="mb-2">Despesas por categoria</h6>
                    </div>
                  </div>
                  <div class="col-12">

                    <div class="row">
                      <div class="col-lg-6 mx-auto">

                        <DonutChart v-if="store.state.categoriesChartData.length > 0 && !store.state.loading_categoriesChart" :chartData="store.state.categoriesChart.chartData" :chartOptions="store.state.categoriesChart.chartOptions"></DonutChart>

                        <SkeletonLoad v-if="store.state.loading_categoriesChart"
                            :width="'250px'"
                            :height="'250px'"
                            :class="'rounded-circle mx-auto mb-4'"
                        />

                      </div>
                    </div>

                  </div>
                </div>

              </div>

            </div>
            
          </div>

          <div class="col-12 col-md-4">

            <div class="row">

              <div class="col-12">
                
                <div class="card">

                  <div class="p-3 pb-1 card-header">

                    <div class="d-flex justify-content-between">
                      <h6 class="mb-2">Lançamentos Pendentes</h6>
                    </div>

                    <ul class="nav nav-tabs no-border" id="transactions-tab" role="tablist">
                      <li class="nav-item" role="presentation">
                          <button class="nav-link active despesa" id="out-transactions-tab" data-bs-toggle="tab" data-bs-target="#out-transactions" type="button" role="tab" aria-controls="out-transactions" aria-selected="true">Despesas</button>
                      </li>
                      <li class="nav-item" role="presentation">
                          <button class="nav-link" id="in-transactions-tab" data-bs-toggle="tab" data-bs-target="#in-transactions" type="button" role="tab" aria-controls="in-transactions" aria-selected="false">Receitas</button>
                      </li>
                    </ul>

                  </div>

                  <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="out-transactions" role="tabpanel" aria-labelledby="out-transactions-tab">

                      <div v-if="store.state.loading_transactions" class="col-12">

                      <div class="row align-items-center row-transactions">

                          <div class="col-2 col-lg-1 ms-3 text-center">
                            <SkeletonLoad
                                :width="'45px'"
                                :height="'45px'"
                            />
                          </div>
                          <div class="col-7 col-lg-8">
                              <div class="row align-items-center">
                                  <div class="col-12 col-lg-6">
                                    <SkeletonLoad
                                        :width="'80px'"
                                        :height="'20px'"
                                        :class="'ms-2'"
                                    />
                                    <SkeletonLoad
                                        :width="'120px'"
                                        :height="'20px'"
                                        :class="'ms-2 mt-1'"
                                    />
                                  </div>
                                  <div class="col-12 col-lg-6 text-lg-center">
                                    <SkeletonLoad
                                      :width="'120px'"
                                      :height="'20px'"
                                    />
                                  </div>
                              </div>
                          </div>
                          
                          <div class="col-2 text-end">
                              
                          </div>
                          
                          

                      </div>

                      </div>

                      <div class="col-12 text-center mt-4" v-if="store.state.transactions_out.length == 0 && !store.state.loading_transactions">
                        <p>Nenhum lançamento cadastrado.</p>
                      </div>

                      <div v-if="!store.state.loading_transactions" class="contas mb-3">
                      <transactions
                        v-for="(btp, index) in store.state.transactions_out" :key="index"
                        :transaction = btp
                        @pay="payTransation"
                      />
                      </div>

                    </div>
                    <div class="tab-pane fade" id="in-transactions" role="tabpanel" aria-labelledby="in-transactions-tab">

                      <div v-if="store.state.loading_transactions" class="col-12">

                        <div class="row align-items-center row-transactions">

                            <div class="col-2 col-lg-1 ms-3 text-center">
                              <SkeletonLoad
                                  :width="'45px'"
                                  :height="'45px'"
                              />
                            </div>
                            <div class="col-7 col-lg-8">
                                <div class="row align-items-center">
                                    <div class="col-12 col-lg-6">
                                      <SkeletonLoad
                                          :width="'80px'"
                                          :height="'20px'"
                                          :class="'ms-2'"
                                      />
                                      <SkeletonLoad
                                          :width="'120px'"
                                          :height="'20px'"
                                          :class="'ms-2 mt-1'"
                                      />
                                    </div>
                                    <div class="col-12 col-lg-6 text-lg-center">
                                      <SkeletonLoad
                                        :width="'120px'"
                                        :height="'20px'"
                                      />
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-2 text-end">
                                
                            </div>
                            
                            

                        </div>

                        </div>

                        <div class="col-12 text-center mt-4" v-if="store.state.transactions_in.length == 0 && !store.state.loading_transactions">
                          <p>Nenhum lançamento cadastrado.</p>
                        </div>

                        <div v-if="!store.state.loading_transactions && store.state.transactions_in.length > 0" class="contas mb-3">
                        <transactions
                          v-for="(btr, index) in store.state.transactions_in" :key="index"
                          :transaction = btr
                          @pay="payTransation"
                        />
                        </div>

                    </div>
                  </div>

                </div>
                
              </div>

            </div>

          </div>
          
        </div>

      </div>
    </div>
  </div>

</template>

<style>
    .contas{
        max-height: 500px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    #transactions-tab .nav-link.active{
        border-bottom: 2px solid #20c997 !important;
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
    }

    #transactions-tab .nav-link.active.despesa{
      border-bottom: 2px solid #f5365c !important;
    }
    
    #transactions-tab .nav-link{
        border: none !important;
        color:#000;
        width: 100%;
    }

    #transactions-tab .nav-item{
      width:50%;
    }


</style>
