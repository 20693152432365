<script setup>
import SkeletonLoad from '../../views/components/SkeletonLoad.vue';


defineProps({
  rowReverse: {
    type: Boolean,
    default: false,
  },
  title: {
    type: [Object, String],
    required: true,
    text: {
      type: String,
    },
    color: {
      type: String,
    },
  },
  description: {
    type: String,
    default: "",
  },
  value: {
    type: [Object, String, Number],
    required: true,
    text: {
      type: [String, Number],
    },
    color: {
      type: String,
    },
  },
  future_value: {
    type: [Object, String, Number],
    required: true,
    text: {
      type: [String, Number],
    },
    color: {
      type: String,
    },
  },
  icon: {
    type: [String, Object],
    component: {
      type: String,
    },
    background: {
      type: String,
    },
    shape: {
      type: String,
    },
    default: () => ({
      background: "bg-white",
      shape: " border-radius-md",
    }),
  },
  classContent: {
    type: String,
    default: "",
  },
  loading: {
    type: Boolean,
    default: true,
  },
});

function formatMoney(v) {
      let total = parseFloat(v);

      return total.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    }
</script>
<template>
  <div class="mb-3 card">
    <div class="p-3 card-body">
      <div
        class="d-flex row"
        :class="rowReverse ? '' : 'flex-row-reverse justify-content-between'"
      >
        <div
          class="text-center shadow icon icon-shape align-self-center col-2"
          :class="[
            typeof icon === 'object'
              ? `${icon.background} ${icon.shape}`
              : 'border-radius-md',
            rowReverse ? 'ms-2 me-n2' : '',
          ]"
        >
          <i
            class="text-lg opacity-10"
            :class="typeof icon === 'string' ? icon : icon.component"
            aria-hidden="true"
          ></i>
        </div>
        <div class="col-10 col-lg-12 mt-lg-2">
          <div class="row">
            <div class="col-6">
              <p
                class="mb-0 ms-1 font-weight-bold text-dark"
              >
                {{ typeof title === "string" ? title : title.text }}
              </p>
              <span class="text-sm ms-1">Previsto</span>
            </div>
            <div class="col-6 text-end">
              <SkeletonLoad
                  v-if="loading"
                  :width="'110px'"
                  :height="'25px'"
              />
              <h6 v-else :class="`mb-0 font-weight-bolder text-dark ${value.color}`">
                {{
                  (value && typeof value === "string") ||
                  (value && typeof value === "number")
                    ? formatMoney(value)
                    : formatMoney(value.text)
                }}
                
              </h6>
              <span class="text-sm" v-if="!loading">
              {{
                (future_value && typeof future_value === "string") ||
                (future_value && typeof future_value === "number")
                  ? formatMoney(future_value)
                  : formatMoney(future_value.text)
              }}
            </span>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    <div class="col-12 mt-n2 mb-1">
      
    </div>
  </div>
</template>
