<script setup>
import { onBeforeUnmount, onBeforeMount } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import cookie from '../assets/js/cookies.js';
import loadGif from "@/assets/img/loading.gif"
import router from "../router/index.js";


const body = document.getElementsByTagName("body")[0];

const store = useStore();

onBeforeMount(() => {

  let session = cookie.get('user_agive_session');

  if(session != null){
    router.push({name: 'Visão Geral'});
  }

  store.state.hideConfigButton = true;
  store.state.showNavbar = false;
  store.state.showSidenav = false;
  store.state.showFooter = false;
  body.classList.remove("bg-gray-100");

  store.state.email = {
    hasError: false,
    messageError: '',
    value: '',
  };

  store.state.password = {
    hasError: false,
    messageError: '',
    value: '',
  };

  store.state.remember = {
    checked: false,
  };

  store.state.login = {
    hasError: false,
    messageError: ''
  };

  store.state.isLoging = false;
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  store.state.showNavbar = true;
  store.state.showSidenav = true;
  store.state.showFooter = true;
  body.classList.add("bg-gray-100");
});

const login = () => {

  store.state.isLoging = true;

  let data = new FormData();

  store.state.email.hasError = false;
  store.state.password.hasError = false;
  store.state.login.hasError = false;

  let device = getDeviceName();

  data.append('email', store.state.email.value);
  data.append('password', store.state.password.value);
  data.append('remember', store.state.remember.checked);
  data.append('device', device);

  axios.post('/auth', data)
  .then(res => {

    if(res.data.error){

      let messages = res.data.messages;

      Object.entries(messages).forEach((value) => {

        if(value[0] == 'email'){
          store.state.email.hasError = true;
          store.state.email.messageError = value[1];
        }

        else if(value[0] == 'password'){
          store.state.password.hasError = true;
          store.state.password.messageError = value[1];
        }

        else if(value[0] == 'error'){
          store.state.login.hasError = true;
          store.state.login.messageError = value[1];
        }

      })

      store.state.isLoging = false;

    }
    else{

      let expires = 86400;

      if(store.state.remember.checked){

        expires = 31622400;

      }

      store.state.email = {
        hasError: false,
        messageError: '',
        value: '',
      };

      store.state.password = {
        hasError: false,
        messageError: '',
        value: '',
      };

      store.state.remember = {
        checked: false,
      };

      cookie.add('user_agive_session', res.data, expires);

      window.location.href = '/inicio';

    }

  })
  .catch(err => {
    console.error(err); 
  })

}

const iosDeviceMapping = new Map([
  ["320x480", "IPhone 4S, 4, 3GS, 3G, 1st gen"],
  ["320x568", "IPhone 5, SE 1st Gen,5C, 5S"],
  ["375x667", "IPhone SE 2nd Gen, 6, 6S, 7, 8"],
  ["375x812", "IPhone X, XS, 11 Pro, 12 Mini, 13 Mini"],
  ["390x844", "IPhone 13, 13 Pro, 12, 12 Pro"],
  ["414x736", "IPhone 8+"],
  ["414x896", "IPhone 11, XR, XS Max, 11 Pro Max"],
  ["428x926", "IPhone 13 Pro Max, 12 Pro Max"],
  ["476x847", "IPhone 7+, 6+, 6S+"],
  ["744x1133", "IPad Mini 6th Gen"],
  [
    "768x1024",
    "IPad Mini (5th Gen), IPad (1-6th Gen), iPad Pro (1st Gen 9.7), Ipad Mini (1-4), IPad Air(1-2)  ",
  ],
  ["810x1080", "IPad 7-9th Gen"],
  ["820x1180", "iPad Air (4th gen)"],
  ["834x1194", "iPad Pro (3-5th Gen 11)"],
  ["834x1112", "iPad Air (3rd gen), iPad Pro (2nd gen 10.5)"],
  ["1024x1366", "iPad Pro (1-5th Gen 12.9)"],
]);

const desktopDeviceMapping = new Map([
  ["Win32", "Windows"],
  ["Linux", "Linux"],
  ["MacIntel", "Mac OS"],
  ["Windows", "Windows"]
]);

// get device name for android
const getAndroidDeviceName = () => {
  const androidUserAgentString = window.navigator.userAgent.slice(window.navigator.userAgent.indexOf("Android"));
  const androidDeviceName = androidUserAgentString.slice(androidUserAgentString.indexOf("; ") + 1, androidUserAgentString.indexOf(")"));
  if (androidDeviceName) {
    return androidDeviceName.trim().split(" ")[0];
  }

  return "Android";
};

// get device name for ios
const getIosDeviceName = () => {
  const screenResolution = window.screen.width + 'x' + window.screen.height;
  const device = iosDeviceMapping.get(screenResolution);
  if (device) {
    return device;
  }
  return "Iphone";
};

// get device name for desktop
const getDesktopDeviceName = () => {
  const platform = navigator?.userAgentData?.platform || navigator?.platform || "unknown";
  let device = desktopDeviceMapping.get(platform) ?? "Unknown";
  return device;
};

const getDeviceName = () => {

  let device = "";
  
  // check if mobile device
  const isMobileDevice = window.navigator.userAgent
    .toLowerCase()
    .includes("mobi");

  if (isMobileDevice) {
    if (window.navigator.userAgent.includes("Android")) {
      device = getAndroidDeviceName();
    } else {
      device = getIosDeviceName();
    }
  } else {
    device = getDesktopDeviceName();
  }
  
  return device;

}

</script>
<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        
      </div>
    </div>
  </div>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div
              class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0"
            >
              <div class="card card-plain">
                <div class="pb-0 card-header text-center text-lg-start">
                  <h4 class="font-weight-bolder">Acesse sua conta</h4>
                  <p class="mb-0">Digite seu e-mail e senha para entrar</p>
                </div>
                <div class="card-body">
                  <div class="mb-3">
                      <input 
                        type="email" 
                        class="form-control form-control-lg" 
                        id="email" 
                        v-model="store.state.email.value" 
                        placeholder="E-mail"
                        :class="store.state.email.hasError ? 'is-invalid' : ''"
                      >
                      <div id="emailFeedback" class="invalid-feedback">
                        {{ store.state.email.messageError }}
                      </div>
                    </div>
                    <div class="mb-3">
                      <input 
                        type="password" 
                        class="form-control form-control-lg" 
                        id="password" 
                        v-model="store.state.password.value" 
                        placeholder="Senha"
                        :class="store.state.password.hasError ? 'is-invalid' : ''"
                      >
                      <div id="passwordFeedback" class="invalid-feedback">
                        {{ store.state.password.messageError }}
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="form-check">
                          <input 
                            class="form-check-input" 
                            type="checkbox" 
                            id="termsCheck" 
                            v-model="store.state.remember.checked"
                          >
                          <label class="form-check-label" for="termsCheck">
                            Lembrar-me
                          </label>
                          
                      </div>
                      
                    </div>
                    
                    <div class="text-center">
                      <button
                        v-if="!store.state.isLoging"
                        class="my-4 mb-2 btn-lg btn bg-gradient-success w-100"
                        @click="login()"
                      >
                        Entrar
                      </button>
                      <img v-if="store.state.isLoging" :src="loadGif" alt="" width="50">
                    </div>
                    <div v-if="store.state.login.hasError" class="col-12 mt-3">
                      <div class="text-danger text-center">
                        {{ store.state.login.messageError }}
                      </div>
                    </div>
                    <div class="col-12 mt-3 mb-3 text-center text-lg-start">
                      <hr>
                      Ainda não possui conta? <a href="/cadastro"><br class="d-lg-block">Faça o cadastro!</a>
                    </div>
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  
                </div>
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                style="
                  background-image: url(&quot;https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-ill.jpg&quot;);
                  background-size: cover;
                "
              >
                <span class="mask bg-gradient-success opacity-6"></span>
                <h4
                  class="mt-5 text-white font-weight-bolder position-relative"
                >
                  AGIVE FINANÇAS
                </h4>
                <p class="text-white position-relative">
                  Controle seu financeiro de forma fácil e simplificada!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
